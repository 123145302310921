import "./privacy-policy.scss";

export const PrivacyPolicy = () => {
	return (
		<div className="privacy">
			<a
				href="https://api.health-balance.ru/downloads/privacy.pdf"
				className="privacy-link small-text"
			>
				Политика конфиденциальности
			</a>
			<a
				href="https://api.health-balance.ru/downloads/terms.pdf
"
				className="privacy-link small-text"
			>
				Правила и условия
			</a>
		</div>
	);
};
