// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/image/auth/background-image.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".auth-page {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: cover;\n  margin: -16px;\n  margin-top: -80px;\n  height: 100vh;\n  position: relative;\n}\n.auth-page::before {\n  content: \"\";\n  display: block;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background: rgba(0, 0, 0, 0.37);\n}", "",{"version":3,"sources":["webpack://./src/pages/Authorization/auth-page.scss"],"names":[],"mappings":"AAAA;EACE,2EAAA;EAEA,sBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;AAAF;AACE;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,+BAAA;AACJ","sourcesContent":[".auth-page {\n  background: url('../../assets/image/auth/background-image.jpg') center center\n    no-repeat;\n  background-size: cover;\n  margin: -16px;\n  margin-top: -80px;\n  height: 100vh;\n  position: relative;\n  &::before {\n    content: '';\n    display: block;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.37);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
