// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/image/interview/sportsmen.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialog-page {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: fixed;\n  width: 100%;\n  height: 100vh;\n  left: 0;\n  top: 0;\n  padding: 35px;\n}\n.dialog-page__content {\n  margin-top: 50vh;\n}\n.dialog-page__content h2 {\n  font-size: 35px;\n  font-weight: 700;\n  margin-bottom: 22px;\n}\n.dialog-page__content p {\n  font-size: 15px;\n  margin-bottom: 85px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Interview-page/interview.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAGN;AADI;EACE,eAAA;EACA,mBAAA;AAGN","sourcesContent":[".dialog-page {\n  background-image: url(\"../../assets//image/interview/sportsmen.png\");\n  position: fixed;\n  width: 100%;\n  height: 100vh;\n  left: 0;\n  top: 0;\n  padding: 35px;\n  &__content {\n    margin-top: 50vh;\n    h2 {\n      font-size: 35px;\n      font-weight: 700;\n      margin-bottom: 22px;\n    }\n    p {\n      font-size: 15px;\n      margin-bottom: 85px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
