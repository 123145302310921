import React from "react";
import "./Footer.scss";
import { VERSION_NAME } from "../../utils/globalConstants";
import whatsappBtn from "../../assets/image/social/whatsapp-btn.svg";
import telegramBtn from "../../assets/image/social/telegram-btn.svg";

export const Footer = () => {
	return (
		<div className="footer">
			<div className="footer__body">
				<div className="footer__body-socials">
					<span>Техподдержка: </span>
					<a className={"footer__item"} href="https://wa.me/79099481168">
						<img src={whatsappBtn} alt="" />
					</a>
					<a className={"footer__item"} href="https://t.me/NO_morena">
						<img src={telegramBtn} alt="" />
					</a>
				</div>
				<a className={"footer__item"} href="https://health-balance.ru/wiki">
					<span>Инструкция: </span>https://health-balance.ru/wiki
				</a>
				<div className={"footer__item"}>
					<span>Версия: </span>
					{VERSION_NAME}
				</div>
			</div>
		</div>
	);
};
